<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminMeetings.title')
          }}<v-tooltip top v-if="admin === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminMeetings.createButton') }}
              </v-btn></template
            >
            <span>{{ $t('Views.AdminMeetings.createText') }}</span>
          </v-tooltip>
        </h3>
      </div>
      <simple-list
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="''"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-2'"
        :sortBy="''"
        :page="page"
        :pages="pages"
        :showCreate="true"
        :showDelete="admin === 1 ? true : false"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" height="auto" :width="'80%'" :adaptive="true">
      <div class="card" style="padding: 2rem">
        <h3 class="text-center" v-if="flow == 'create'" style="margin-bottom: 20px">
          {{ $t('Views.AdminMeetings.createText') }}
        </h3>
        <h3 class="text-center" v-if="flow == 'edit'" style="margin-bottom: 20px">
          {{ $t('Views.AdminMeetings.editText') }}
        </h3>

        <v-form ref="form" v-model="valid" style="width: 100%">
          <v-row class="mx-0">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :readonly="admin === 0"
                :rules="[rules.required]"
                :items="statusOptions"
                :item-text="'name'"
                :item-value="'code'"
                v-model="status"
                :label="$t('Views.AdminMeetings.labelStatus')"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminMeetings.labelName')"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.max]"
                :label="$t('Views.AdminMeetings.labelDescription')"
                v-model="description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.url]"
                :label="$t('Views.AdminMeetings.labelLink')"
                v-model="link"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row class="m-0">
                <a
                  v-if="flow == 'edit' && item.file"
                  target="_blank"
                  rel="noopener"
                  :href="item.file.route"
                  download
                  ><v-btn color="#aaa" outlined dark class="ma-2 white--text">
                    {{ $t('Views.AdminMeetings.labelDownload') }}
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn></a
                >
                <v-file-input
                  v-if="admin === 1"
                  v-model="file"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept=".pdf, .docx, .pptx"
                  :label="$t('Views.AdminMeetings.labelFile')"
                ></v-file-input>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" class="d-flex align-items-center">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="[rules.required]"
                    :label="$t('Views.AdminStatementsAccount.labelDate')"
                    v-model="datetime"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="admin === 0"
                  v-model="datetime"
                  :rules="[rules.required]"
                  min="2000-01-01"
                  show-current
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" class="d-flex align-items-center">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    :label="$t('Views.AdminMeetings.labelTime')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  full-width
                  use-seconds
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-btn
              :dark="valid"
              style="margin: auto"
              v-if="flow == 'create'"
              :disabled="!valid"
              color="#0c2c7c"
              @click="createItem()"
            >
              {{ $t('Views.AdminMeetings.createButton') }}
            </v-btn>
            <v-btn
              :dark="valid"
              style="margin: auto"
              v-if="flow == 'edit'"
              :disabled="!valid || admin === 0"
              color="#0c2c7c"
              @click="saveItem()"
            >
              {{ $t('Views.AdminMeetings.saveButton') }}
            </v-btn>

            <v-btn style="margin: auto" dark color="#0c2c7c" @click="reset">
              {{ $t('Views.AdminMeetings.cleanButton') }}
            </v-btn>
            <v-btn
              dark
              style="margin: auto"
              color="#0c2c7c"
              @click="$modal.hide('crudModal')"
            >
              {{ $t('Views.AdminMeetings.cancelButton') }}
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import { Datetime } from 'vue-datetime'
  import { toLocaleDateString, toLocaleTimeString } from '@/utils/helpers'

  export default {
    name: 'Meetings',
    props: ['admin'],
    components: {
      SimpleList,
      Datetime,
    },
    data() {
      return {
        config: {},
        loading: false,
        items: [],
        menu: false,
        menu2: false,
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        filters: {
          search: '',
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        file: null,
        name: '',
        description: '',
        link: '',
        status: 0,
        datetime: '',
        time: '',
        statusOptions: [
          { code: 1, name: 'Programada' },
          { code: 2, name: 'Finalizada' },
          { code: 3, name: 'Cancelada' },
        ],
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
          email: (v) => (v ? /.+@.+\..+/.test(v) : true) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 1MB',
        },
      }
    },
    computed: {
      headers() {
        return [
          { text: this.$t('Views.AdminMeetings.headerName'), value: 'name' },
          { text: this.$t('Views.AdminMeetings.headerStatus'), value: 'statusText' },
          {
            text: this.$t('Views.AdminMeetings.headerDate'),
            value: 'dateText',
            width: '200px',
          },
          { text: '', value: 'actions', align: 'center', width: '200px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminMeetings.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminMeetings.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminMeetings.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminMeetings.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminMeetings.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    created() {
      this.getMeetings()
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      toLocaleTimeString: toLocaleTimeString,

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getMeetings()
      },
      async getMeetings() {
        this.loading = true
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        axios.get('meetings?page=' + this.page + orderString).then((response) => {
          this.items = response.data.data
          if (this.items.length < 1) {
            this.loadingText = 'No hay registros'
          }
          this.totalItems = response.data.total
          this.itemsPerPage = response.data.per_page
          this.page = response.data.current_page
          this.pages = response.data.last_page
          this.items.map((x) => {
            switch (x.status) {
              case 1:
                x.statusText = 'Programada'
                break
              case 2:
                x.statusText = 'Finalizada'
                break
              case 3:
                x.statusText = 'Cancelada'
                break
              default:
                x.statusText = ''
                break
            }
            x.dateText =
              this.toLocaleDateString(new Date(x.datetime)) +
              ' ' +
              this.toLocaleTimeString(new Date(x.datetime))
            x.file = x.uploads.find((upload) => upload.type === 'file')
          })
          this.loading = false
        })
      },

      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      async handleCreate() {
        const datetime = new Date()
        this.flow = 'create'
        this.item = {}
        this.file = null
        this.name = ''
        this.description = ''
        this.link = ''
        this.status = 1
        this.datetime = new Date(datetime + datetime.getTimezoneOffset())
          .toISOString()
          .split('T')[0]
        this.time = `${datetime.getHours()}:${datetime.getMinutes()}`
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        const datetime = new Date(
          new Date(this.item.datetime.replace(' ', 'T')).getTime() -
            new Date().getTimezoneOffset() * 60 * 1000
        )
        this.file = null
        this.name = this.item.name
        this.description = this.item.description
        this.link = this.item.link
        this.status = this.item.status
        this.datetime = `${datetime.getFullYear()}-${
          datetime.getMonth() + 1
        }-${datetime.getDate()}`
        this.time = `${datetime.getHours()}:${datetime.getMinutes()}:${datetime.getSeconds()}`
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.file) {
          formData.append('file', this.file)
        }
        formData.append('fileType', 'file')
        formData.append('name', this.name)
        formData.append('status', this.status)
        if (this.description && this.description !== '') {
          formData.append('description', this.description)
        }
        if (this.link && this.link !== '') {
          formData.append('link', this.link)
        }
        formData.append('datetime', this.settingDate(this.datetime + 'T' + this.time))
        axios.post('meetings', formData, this.config).then(() => {
          this.getMeetings()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.file) {
          formData.append('file', this.file)
        }
        formData.append('fileType', 'file')
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        formData.append('status', this.status)
        if (this.description && this.description !== '') {
          formData.append('description', this.description)
        }
        if (this.link && this.link !== '') {
          formData.append('link', this.link)
        }
        formData.append('datetime', this.settingDate(this.datetime + 'T' + this.time))
        axios.post('meetings/' + this.item.id, formData, this.config).then(() => {
          this.getMeetings()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        axios.delete('meetings/' + item.id).then((response) => {
          this.items = []
          this.getMeetings()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getMeetings()
      },

      settingDate(datetimeString) {
        const datetime = new Date(datetimeString)
        return (
          datetime.toISOString().split('T')[0] +
          ' ' +
          datetime.toISOString().split('T')[1]
        )
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .v-picker__title {
    background-color: #0c2c7c !important;
  }
  .v-date-picker-table .v-btn.v-btn--active {
    color: #000000a1 !important;
  }
</style>
